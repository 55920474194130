import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";
import { AuthenticationService } from "./authentication.service";
@Injectable({
  providedIn: "root"
})
export class BookingsService {
  // SERVER = "https://vcare-app.herokuapp.com";
  // SERVER = "http://localhost:5000";
  SERVER = "https://api.vcareservice.com";

  headers: HttpHeaders;
  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    this.headers = new HttpHeaders();
  }
  bookinghistory(data): Observable<any> {
    this.headers = this.headers.set(
      "x-access-token",
      this.authenticationService.currentUserValue.access_token
    );
    return this.httpClient
      .post(this.SERVER + "/bookings/userspecify/fetch", data, {
        headers: this.headers
      })
      .map((res: Response) => res);
  }
  bookingInvoice(data): Observable<any> {
    this.headers = this.headers.set(
      "x-access-token",
      this.authenticationService.currentUserValue.access_token
    );
    return this.httpClient
      .post(this.SERVER + "/bookings/invoice", data, {
        headers: this.headers
      })
      .map((res: Response) => res);
  }
  markpayment(data): Observable<any> {
    this.headers = this.headers.set(
      "x-access-token",
      this.authenticationService.currentUserValue.access_token
    );
    return this.httpClient
      .put(this.SERVER + "/bookings/paymentmark", data, {
        headers: this.headers
      })
      .map((res: Response) => res);
  }
  parkingDetials(): Observable<any> {
    this.headers = this.headers.set(
      "x-access-token",
      this.authenticationService.currentUserValue.access_token
    );
    return this.httpClient
      .get(this.SERVER + "/bookings/parkingDetails", {
        headers: this.headers
      })
      .map((res: Response) => res);
  }
  paymentmethodsubmit(data): Observable<any> {
    this.headers = this.headers.set(
      "x-access-token",
      this.authenticationService.currentUserValue.access_token
    );
    return this.httpClient
      .put(this.SERVER + "/bookings/paymentmethod", data, {
        headers: this.headers
      })
      .map((res: Response) => res);
  }
}
