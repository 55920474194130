import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./screens/login/login.component";
import { AllOrdersListComponent } from "./screens/all-orders-list/all-orders-list.component";
import { AuthGuardGuard } from "./_helpers";
import { PrintinvoiceComponent } from "./screens/printinvoice/printinvoice.component";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "", redirectTo: "login", pathMatch: "full" },
  // { path: "**", redirectTo: "login", pathMatch: "full" },
  {
    path: "Allorderlist",
    component: AllOrdersListComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: "printinvoice",
    component: PrintinvoiceComponent,
    canActivate: [AuthGuardGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
    // RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
