import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../_models";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  // SERVER = "https://vcare-app.herokuapp.com";
  SERVER = "https://api.vcareservice.com";

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  SendOtp(body) {
    return this.http.post<any>(this.SERVER + "/auth/createotp", body).pipe(
      map(data => {
        return data;
      })
    );
  }
  login(body) {
    return this.http.post<any>(this.SERVER + "/auth/verifyotp", body).pipe(
      map(res => {
        if (res.data[0].status == "V") {
          let customeresponse = {
            id: res.data[1].user.userID,
            PhoneNumber: res.data[1].user.phoneNumber,
            access_token: res.data[1].access_token
          };
          localStorage.setItem("currentUser", JSON.stringify(customeresponse));
          this.currentUserSubject.next(customeresponse);
          return res;
        } else {
          this.logout();
        }

        return res;
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    this.router.navigate(["/login"]);
  }
}
