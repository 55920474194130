import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class PrintinvoiceService {
  Fetch_individual = {
    Amount: "",
    availedServices: [],
    bookingID: "",
    createdAt: "",
    customerDetails: {
      carModel: "",
      carType: "",
      createdAt: "",
      customerID: "",
      customerMobileNumber: "",
      customerName: "",
      plateNumber: "",
      updatedAt: ""
    },
    customerID: "",
    id: "",
    offerPrize: "",
    parkingMapID: "",
    paymentStatus: Boolean,
    promoCode: "",
    updatedAt: "",
    netAmount: 0
  };
  constructor() {}
  getprintinvoice() {
    // localStorage.setItem("bookid", this.Fetch_individual.id);
    return localStorage.getItem("bookid");
  }
}
