import { Component, OnInit, HostListener } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { AuthenticationService } from "src/app/Services/authentication.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

declare var $: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  OtpSubmithide = true;
  Otpbuttondisable = false;
  loading = false;
  returnUrl: string;
  erroralert = true;
  resendEnabled = false;
  loader = true;
  _countDown: any;
  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(["/Allorderlist"]);
      this.returnUrl =
        this.route.snapshot.queryParams["returnUrl"] || "/Allorderlist";
    }
  }
  @HostListener("paste", ["$event"])
  blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener("copy", ["$event"])
  blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener("cut", ["$event"])
  blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
  login_data = {
    phoneNumber: "",
    verfication_code: "",
    Status: "",
    verfication_id: "",
    countrycode: "+971"
  };

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      phonenumber: [
        "",
        [Validators.required, Validators.minLength(8), Validators.maxLength(12)]
      ],

      countrycode: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(4)]
      ]
    });
  }
  ngAfterViewInit() {
    $('input[type="text"]').keypress(e => {
      var code = e.keyCode || e.which;
      if (code === 13) this.SendOtp();
    });
  }
  get f() {
    return this.loginForm.controls;
  }
  SendOtp() {
    this.submitted = true;
    console.log(this.loginForm);

    if (this.loginForm.invalid) return;

    let payload = {
      ...this.login_data,
      countrycode: this.login_data.countrycode.replace("+", ""),
      phoneNumber1: this.login_data.phoneNumber
    };
    this.erroralert = true;

    this.Otpbuttondisable = true;
    this.loader = false;
    this.authenticationService.SendOtp(payload).subscribe(
      data => {
        this.submitted = false;
        this.resendEnabled = false;
        this.openSnackBar("OTP sent succesfully", "close");
        this.countdown(0, 30);
        setTimeout(() => {
          this.resendEnabled = true;
        }, 30000);
        this.loginForm.addControl(
          "otp",
          new FormControl("", [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4)
          ])
        );
        this.loader = true;
        this.login_data.verfication_id = data.data.verfication_id.toString();
        this.Otpbuttondisable = true;
        this.OtpSubmithide = false;
        this.erroralert = true;
      },
      err => {
        this.loader = true;
        this.Otpbuttondisable = false;
        console.log(err.error);
        this.onFailed(err.error);
      }
    );
  }
  errortext;
  onFailed(err) {
    this.loader = true;
    this.erroralert = false;
    this.errortext = err.error;
  }
  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }
    if (!this.login_data.verfication_code) {
      this.erroralert = false;
      this.errortext = "Please Enter OTP";
      return;
    }
    this.erroralert = true;
    this.errortext = "";
    this.loading = true;
    this.loader = false;
    let payload = {
      ...this.login_data,
      phoneNumber1: this.login_data.phoneNumber,
      verfication_code: parseInt(this.login_data.verfication_code),
      countrycode: this.login_data.countrycode.replace("+", "")
    };
    this.authenticationService.login(payload).subscribe(
      data => {
        this.loader = true;
        if (data.data[0].status == "V") {
          this.erroralert = false;

          this.router.navigate([this.returnUrl || "/Allorderlist"]);
        } else {
          data.data[0].remarks = "Invalid OTP";
          this.errortext = data.data[0].remarks;
          this.erroralert = false;
        }
      },
      error => {
        console.log(error);
        this.loading = false;
        this.loader = true;
      }
    );
  }
  omit_alphabets_char(event) {
    let keepnumber;

    if (
      this.login_data.phoneNumber &&
      this.login_data.phoneNumber.length <= 12
    ) {
      keepnumber = this.login_data.phoneNumber;
      var k;
      k = event.charCode;
      return k > 47 && k < 58;
    }
    this.login_data.phoneNumber = keepnumber;
  }
  onReset() {
    this.loader = true;
    this.OtpSubmithide = true;
    this.Otpbuttondisable = false;
    this.submitted = false;
    this.loginForm.reset();
    this.erroralert = true;
    this.errortext = "";
    this.loginForm.removeControl("otp");
    this.login_data.verfication_code = "";
    this.login_data.countrycode = "+971";
    if (this._countDown) clearTimeout(this._countDown);
    let element = document.querySelector("#count");
    element.innerHTML = "";
  }
  resend() {
    this.loader = true;
    this.OtpSubmithide = true;
    this.Otpbuttondisable = false;
    this.loginForm.removeControl("otp");
    this.login_data.verfication_code = "";
    this.SendOtp();
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }
  appendPlusSymbol(event) {
    if (
      this.login_data.countrycode &&
      !this.login_data.countrycode.includes("+")
    ) {
      this.login_data.countrycode = `+${this.login_data.countrycode}`;
    }
  }
  countdown(minutes, seconds) {
    var element, endTime;

    element = document.querySelector("#count");
    endTime = +new Date() + 1000 * (60 * minutes + seconds) + 500;
    this.updateTimer(element, endTime);
  }
  updateTimer(element, endTime) {
    function twoDigits(n) {
      return n <= 9 ? "0" + n : n;
    }
    var hours, mins, msLeft, time;
    msLeft = endTime - +new Date();
    if (msLeft < 1000) {
      element.innerHTML = "";
    } else {
      time = new Date(msLeft);
      hours = time.getUTCHours();
      mins = time.getUTCMinutes();
      element.innerHTML =
        (hours ? hours + ":" + twoDigits(mins) : mins) +
        ":" +
        twoDigits(time.getUTCSeconds());
      this._countDown = setTimeout(() => {
        this.updateTimer(element, endTime);
      }, time.getUTCMilliseconds() + 500);
    }
  }
}
