import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./screens/login/login.component";
import { ReactiveFormsModule } from "@angular/forms";
import { AllOrdersListComponent } from "./screens/all-orders-list/all-orders-list.component";
import { PagerService } from "./Services/pager.service";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  JwtInterceptor,
  ErrorInterceptor,
  fakeBackendProvider
} from "./_helpers";
import { PrintinvoiceComponent } from "./screens/printinvoice/printinvoice.component";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { RoundPipe } from "./_helpers/round";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSnackBarModule } from "@angular/material";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AllOrdersListComponent,
    PrintinvoiceComponent,
    RoundPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    BrowserAnimationsModule,
    MatSnackBarModule
  ],
  providers: [
    PagerService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // provider used to create fake backend
    fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
