import { Component, OnInit } from "@angular/core";
import { PagerService } from "src/app/Services/pager.service";
import { BookingsService } from "src/app/Services/bookings.service";
import { AuthenticationService } from "src/app/Services/authentication.service";
import { Router } from "@angular/router";
import { PrintinvoiceService } from "src/app/_models/printinvoice.service";
import { LocationStrategy } from "@angular/common";
import { filterBySearch } from "src/app/utils/filters";
import { MatSnackBar } from "@angular/material/snack-bar";

declare var $: any;
@Component({
  selector: "app-all-orders-list",
  templateUrl: "./all-orders-list.component.html",
  styleUrls: ["./all-orders-list.component.scss"]
})
export class AllOrdersListComponent implements OnInit {
  constructor(
    private pagerService: PagerService,
    private bookingservice: BookingsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private printinvoiceService: PrintinvoiceService,
    private location: LocationStrategy,
    private _snackBar: MatSnackBar
  ) {}
  closehide = true;
  orderlist;
  searchText;
  presearchText;
  modalhide = false;
  Plate_Number = null;
  beforepaycalss = "btn view-button btn-sm bg-gradiant-red btn-block";
  afterpaycalss = "btn view-button btn-sm bg-gradiant-green btn-block";
  Fetch_individual = {
    Amount: "",
    availedServices: [],
    bookingID: "",
    createdAt: "",
    customerDetails: {
      carModel: "",
      carType: "",
      createdAt: "",
      customerID: "",
      countrycode: "",
      customerMobileNumber: "",
      customerName: "",
      plateNumber: "",
      updatedAt: ""
    },
    customerID: "",
    id: "",
    offerPrize: "",
    parkingMapID: "",
    paymentStatus: Boolean,
    promoCode: "",
    updatedAt: "",
    netAmount: 0
  };
  private allItems: any[];
  pager: any = {};
  pagedItems: any[];
  nodata = true;
  paybuttondisable = true;
  loader = false;
  cashChecked = false;
  creditChecked = false;
  ngOnInit() {
    // location.reload();
    this._refresh();
  }
  ngAfterViewInit() {
    $("#content").css("padding-top", $("#nav").height() + 17);
  }
  Fetch_individual_userdata(data) {
    this.cashChecked = false;
    this.creditChecked = false;
    this.paybuttondisable = true;
    this.Fetch_individual = data;
  }
  Fetch_individual_forinvoice(data) {
    $("#Services").modal("hide");
    this.Fetch_individual = data;

    this.printinvoiceService.Fetch_individual = this.Fetch_individual;
    localStorage.setItem("bookid", this.Fetch_individual.id);

    if (data.paymentStatus) {
      // window.open(url, "_blank");
      let url = location.href;
      let result = url.match(/[^#]+/g);
      let url2 = result[0] + "#/printinvoice";
      console.log(result);
      window.open(url2, "_blank");

      // this.router.navigate(["/printinvoice"]);
    }
  }
  setPage(page: number, action = "") {
    if (action == "button") {
      window.scrollTo(0, 0);
    }
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    this.pagedItems = this.allItems.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    console.log(this.pagedItems);

    this.orderlist = this.pagedItems;
  }
  Serach_numberplate() {
    this.searchText = this.searchText;
  }
  keepData;
  _refresh() {
    // this.searchText = "";
    this.loader = false;
    this.Plate_Number = "";
    let body = {
      parkingmap: ""
    };
    this.bookingservice.bookinghistory(body).subscribe(
      data => {
        this.loader = true;
        if (data.data.length == 0) {
          this.nodata = false;
        } else {
          this.nodata = true;
        }
        data.data = data.data.map((item, index) => ({
          ...item,
          si_no: index + 1,
          customerDetails: {
            ...item.customerDetails,
            mobileNumber: `+${item.customerDetails.countrycode}-${item
              .customerDetails.customerMobileNumber}`,
            customerName: item.customerDetails.customerName
              ? item.customerDetails.customerName
              : "--"
          }
        }));

        this.allItems = data.data;

        this.keepData = [];
        this.keepData = this.allItems;
        this.setPage(1);
        this.reload();
      },
      err => {
        console.log(err), (this.loader = true);
      }
    );
  }
  markpayment(body) {
    this.bookingservice.markpayment(body).subscribe(
      data => {
        if (data.data[0] == 1) {
          this.submitpaymentmethod();
        }
      },
      err => console.log(err)
    );
  }
  logout() {
    this.authenticationService.logout();
  }
  Cancel() {
    if (this.keepData.length) this.nodata = true;
    this.closehide = true;
    this.presearchText = "";
    this.searchText = "";
    this.allItems = this.keepData;
    this.setPage(this.pager.currentPage);
  }

  paymentmethodbody: any = {
    id: "",
    paymentMethod: ""
  };
  paymentmethod(event) {
    // let element: HTMLElement;
    if (event == "cash") {
      this.cashChecked = true;
      this.creditChecked = false;
    } else {
      this.creditChecked = true;
      this.cashChecked = false;
    }
    this.paybuttondisable = false;
    this.paymentmethodbody = {
      id: this.Fetch_individual.id,
      paymentMethod: event
    };
  }
  reload() {
    this.closehide = false;
    this.allItems = filterBySearch(
      "customerDetails.mobileNumber",
      this.keepData,
      this.searchText,
      true
    ).map((item, index) => ({ ...item, si_no: index + 1 }));
    if (!this.allItems.length) this.nodata = false;
    else this.nodata = true;
    this.setPage(this.pager.currentPage);
    if (!this.searchText) {
      this.closehide = true;
      this.setPage(this.pager.currentPage);
    }
  }
  submitpaymentmethod() {
    this.bookingservice.paymentmethodsubmit(this.paymentmethodbody).subscribe(
      data => {
        $("#Payment").modal("toggle");
        let message = "Payment mark successfully completed.";
        let action = "close";

        this.openSnackBar(message, action);
        $("#Payment").on("hidden.bs.modal", function(e) {
          // $("#alert").addClass("show show-alert");
          // $("#alert").fadeTo(2000, 500).slideUp(1000, function() {
          //   $("#alert").slideUp(1000);
          // });
        });
        this._refresh();
      },
      err => console.log(err)
    );
  }
  logoutConfirmation() {
    $("#logout").modal("show");
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }
}
