import { Component, OnInit } from "@angular/core";
import { PrintinvoiceService } from "src/app/_models/printinvoice.service";
import { BookingsService } from "src/app/Services/bookings.service";
declare var $: any;
@Component({
  selector: "app-printinvoice",
  templateUrl: "./printinvoice.component.html",
  styleUrls: ["./printinvoice.component.scss"]
})
export class PrintinvoiceComponent implements OnInit {
  today: Date;
  invoiceData = {
    Amount: "",
    availedServices: [],
    bookingID: "",
    createdAt: "",
    parkingLotID: "",
    parkingLotName: "",
    parkingLotLocation: "",
    phoneNumber: "",
    usertype: "",
    name: "",
    countrycode: "",
    paymentMethod: "",
    customerDetails: {
      carModel: "",
      carType: "",
      createdAt: "",
      customerID: "",
      countrycode: "",
      customerMobileNumber: "",
      customerName: "",
      plateNumber: "",
      updatedAt: ""
    },
    customerID: "",
    id: "",
    offerPrize: "",
    parkingMapID: "",
    paymentStatus: Boolean,
    promoCode: "",
    updatedAt: "",
    netAmount: 0
  };
  parkingLotData = {
    parkingLotID: "",
    parkingLotName: "",
    parkingLotLocation: "",
    createdAt: "",
    updatedAt: ""
  };
  creditType;
  cashType;
  creditChecked = false;
  cashChecked = true;
  serialNumber;
  constructor(
    private printinvoiceService: PrintinvoiceService,
    private bookinservice: BookingsService
  ) {}
  loader = false;
  ngOnInit() {
    this.today = new Date();
    this.serialNumber = Date.now();
    this.getbooking();
  }
  getbooking() {
    let body = {
      id: this.printinvoiceService.getprintinvoice()
    };

    this.bookinservice.bookingInvoice(body).subscribe(
      data => {
        // this.getparkinDetails();

        this.invoiceData = data.data;

        this.loader = true;
        console.log(data);
        this.PaymentType(data.data.paymentMethod);
      },
      err => {
        console.log(err), (this.loader = true);
      }
    );
  }
  getparkinDetails() {
    this.bookinservice.parkingDetials().subscribe(
      data => {
        this.parkingLotData = data.data[0].parkinglotdetails;
        console.log(this.parkingLotData);
      },
      err => {
        console.log(err);
      }
    );
  }
  print() {
    window.print();
  }
  PaymentType(type) {
    if (type === "cash") {
      this.cashChecked = true;
      this.creditChecked = false;
    } else {
      this.creditChecked = true;
      this.cashChecked = false;
    }
  }
}
